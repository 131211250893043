import { LOCAL_STORAGE_KEYS } from "../constants";

const isLoggedInWithIdV2 = () => {
  try {
    const isV2LoggedIn = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_ID_V2);
    return isV2LoggedIn === "true";
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default isLoggedInWithIdV2;
