import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const SupplierLinks = {
  label: "Suppliers",
  icon: "MarketPlace",
  featureFlag: "copilot-header-suppliers",
  children: [
    {
      label: "Manage Suppliers",
      url: COPILOT_ROUTES.marketplace.basePath,
      children: [],
    },
    {
      label: "Proposals",
      url: COPILOT_ROUTES.marketplace.proposals,
      children: [],
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.marketplace.settings,
      children: [],
    },
  ],
} satisfies ProductNavLinkType;

export default SupplierLinks;
