import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const AnalyticsLink = {
  icon: "Analytics",
  label: "Analytics",
  featureFlag: "copilot-analytics-dashboard",
  children: [
    {
      label: "Sales Dashboard",
      url: COPILOT_ROUTES.analytics.salesDashboard
    },
],
} as ProductNavLinkType;

export default AnalyticsLink;
