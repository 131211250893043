import React from "react";
import _isFunction from "lodash/isFunction";
import { InteractiveIcon, Tooltip } from "ds4-beta";
import {
  StyledAccountSwitcher,
  StyledHeader,
  StyledLogoContainer,
} from "./LeftNavHeader.style";
import { LeftNavHeaderProps } from "./LeftNavHeader.type";
import fabricLogo from "./fabricLogo";

const LeftNavHeader = ({
  collapsed,
  // logoURL, // commenting as currently there is no usage
  // largeLogoURL,
  // title,
  onClick,
  // linkURL = "/",
  multiAccount,
  accountSwitcherState,
  toggleAccountSwitcher,
}: LeftNavHeaderProps): JSX.Element => {
  const onClickLogo = (event: React.MouseEvent<HTMLElement>): void => {
    if (_isFunction(onClick)) {
      onClick(event);
    }
  };
  return (
    <StyledHeader
      data-testid="left-nav-header"
      collapsed={collapsed}
      multiAccount={multiAccount}
    >
      {multiAccount && (
        <Tooltip text="Switch account" theme="light" placement="bottomStart">
          <StyledAccountSwitcher
            collapsed={!!collapsed}
            onClick={toggleAccountSwitcher}
            data-testid="account-switcher-toggle"
            role="button"
            aria-label="switch between mutiple accounts"
            tabIndex={1}
          >
            <InteractiveIcon
              variant="dark"
              data-popover-click-state={
                accountSwitcherState ? "open" : "closed"
              }
              size={16}
              iconName="AccountSwitch"
            />
          </StyledAccountSwitcher>
        </Tooltip>
      )}
      {!collapsed && (
        <StyledLogoContainer
          collapsed={!!collapsed}
          multiAccount={multiAccount}
          className="logo"
          onClick={onClickLogo}
        >
          {fabricLogo()}
        </StyledLogoContainer>
      )}
    </StyledHeader>
  );
};

export default LeftNavHeader;
