import { useEffect, useState } from "react";

const useSelectedOrgProducts = (selfOnboarding: boolean) => {
  const [selectedOrgProducts, setSelectedOrgProducts] = useState([]);
  const tenantDetail = window.sessionStorage?.tenantDetail;

  useEffect(() => {
    if (selfOnboarding && tenantDetail) {
      const { configurations } = JSON.parse(tenantDetail);
      const products = Object.keys(configurations);
      setSelectedOrgProducts(products);
    }
  }, [selfOnboarding, tenantDetail]);

  return selectedOrgProducts
}

export default useSelectedOrgProducts;