import styled from "styled-components";
import { theme } from "ds4-beta";

export const StyledLeftNavFooter = styled.div`
  display: flex;
  width: "100%";
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  &:before {
    content: "";
    height: 32px;
    position: absolute;
    top: -32px;
    pointer-events: none;
    user-select: none;
    left: 0;
    right: 0;
    background: ${theme.color.grey[1000]};
    background: linear-gradient(
      180deg,
      ${theme.color.grey[1000]}00 0%,
      ${theme.color.grey[1000]}80 50%,
      ${theme.color.grey[1000]} 100%
    );
  }
`;

export const StyledSupportButton = styled.a<{ collapsed?: boolean }>`
  all: unset;
  width: ${({ collapsed }) => (collapsed ? "fit-content" : "100%")};
  display: flex;
  align-items: center;
  ${theme.typography.navigationAppTitle.inactive}
  .support-label {
    margin-left: 10px;
  }
  cursor: pointer;
  border-radius: ${theme.space[1]};
  min-height: 36px;
  padding: 0px 12px;
  :hover {
    background-color: ${theme.color.navigation.active};
  }
`;

export const StyledFooterButton = styled.div`
  display: flex;
  &[data-collapsed="true"] {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-between;
  align-items: flex-end;
`;

export const DoubleArrowInteractive = styled.div`
  // TODO: Remove this
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: ${theme.space[2]};
  cursor: pointer;
  border-radius: ${theme.space[1]};
  &[data-collapsed="true"] {
    transform: rotate(180deg);
  }
  :hover {
    background-color: ${theme.color.navigation.active};
  }
  svg {
    display: block;
  }
`;

export const StyledDivider = styled.hr`
  width: calc(100% - 24px);
  margin: 0;
  margin-bottom: ${theme.space[2]};
  margin-left: 12px;
  background-color: ${theme.color.grey[700]};
  border: none;
  height: 1px;
  position: relative;
`;
