import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";
import { FABAI_HOME_PAGE_FF, LEFT_NAV_RESTRUCTURE_FF } from "../constants";

const OPERATIONAL_ALERTS_FF = "operational-alerts-nav";

const HomeLinks = {
  icon: "Home",
  label: "Home",
  url: COPILOT_ROUTES.home.activity,
  featureFlag: FABAI_HOME_PAGE_FF,
  flagState: false,
  children: [
    {
      label: "Dashboard",
      url: COPILOT_ROUTES.home.activity,
      featureFlag: {
        flags: [LEFT_NAV_RESTRUCTURE_FF, OPERATIONAL_ALERTS_FF],
        flagExpression: (leftNavRestructure, operationalAlerts) =>
          !leftNavRestructure && operationalAlerts,
      },
      flagState: true,
      children: [],
    },
    {
      children: [],
      label: "Alerts",
      url: COPILOT_ROUTES.home.alertList,
      flagState: true,
      featureFlag: {
        flags: [LEFT_NAV_RESTRUCTURE_FF, OPERATIONAL_ALERTS_FF],
        flagExpression: (leftNavRestructure, operationalAlerts) =>
          !leftNavRestructure && operationalAlerts,
      },
    },
  ],
} satisfies ProductNavLinkType;
export default HomeLinks;
