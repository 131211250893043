import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const ProductLinks = {
  icon: "Product",
  label: "Products",
  featureFlag: "copilot-header-pim",
  children: [
    {
      label: "List",
      children: [
        {
          label: "Items",
          url: COPILOT_ROUTES.pim.items,
        },
        {
          label: "Bundles",
          url: COPILOT_ROUTES.pim.bundles,
        },
      ],
      url: "/",
    },
    {
      label: "Attributes",
      children: [
        {
          label: "Product",
          url: COPILOT_ROUTES.pim.productAttributes,
        },
        {
          label: "Category",
          url: COPILOT_ROUTES.pim.categoryAttributes,
        },
      ],
    },
    {
      label: "Categories",
      url: COPILOT_ROUTES.pim.categories,
    },
    {
      label: "Collections",
      url: COPILOT_ROUTES.pim.collections,
    },
    {
      label: "Background Jobs",
      url: COPILOT_ROUTES.pim.jobs,
      featureFlag: "pim-v3-tenant",
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.pim.settings.basePath,
      children: [
        {
          label: "Attribute groups",
          url: COPILOT_ROUTES.pim.settings.attributeGroup,
        },
        {
          label: "Attribute mapping",
          url: COPILOT_ROUTES.pim.settings.attributeMap,
        },
      ],
    },
  ],
} as ProductNavLinkType;

export default ProductLinks;
