import styled from "styled-components";
import { theme } from "ds4-beta";

export const StyledLoadingContainer = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
`;

export const StyledLeftNavLoadingContainer = styled.div`
  height: 100vh;
  width: 240px;
  display: flex;
  padding: 12px 20px 20px 20px;
  box-sizing: border-box;
  flex-direction: column;
  background: ${theme.color.grey[1000]};
`;

export const StyledContentLoadingContainer = styled.div`
  padding-left: 40px;
  width: 100%;
`;

export const StyledCardLoadingContainer = styled.div`
  background: white;
  margin-top: 26px;
  padding: ${theme.space[5]} 45px 34px ${theme.space[6]};
`;

export const StyleCardRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 51px;
  div:first-child {
    align-items: center;
    display: flex;

    div:first-child {
      margin-right: ${theme.space[4]};
    }
  }
`;

export const SkeletonLogo = styled.div`
  margin-bottom: 18px;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  div {
    background: ${theme.color.grey[700]}!important;
  }
`;

export const SkeletonLeftNavItems = styled.div`
  height: 100%;
  position: relative;
  div {
    margin-bottom: ${theme.space[2]};
  }
`;

export const SkeletonFooter = styled.div`
  display: flex;
  border-top: 1px solid ${theme.color.grey[700]};
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  div {
    margin-bottom: ${theme.space[2]};
  }
  div:last-child {
    margin-bottom: 0;
  }
`;

export const StyledBodyContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
`;
export const SkeletonBreadCrumb = styled.div`
  width: 100%;
  margin-bottom: ${theme.space[6]};
  margin-top: ${theme.space[2]};
  border-bottom: 1px solid ${theme.color.grey[100]};
  > div {
    padding: 0 32px ${theme.space[2]} 32px;
  }
`;
export const SkeletonHeader = styled.div`
  padding: 0 ${theme.space[6]};
`;
export const SkeletonMainBody = styled.div`
  overflow: scroll;
  width: 100%;
  flex: 1 1 auto;
`;
export const SkeletonBodyTitle = styled.div`
  margin-bottom: ${theme.space[2]}!important;
  > div {
    margin-bottom: ${theme.space[2]}!important;
  }
`;

export const SkeletonBodyContent = styled.div`
  div {
    margin-bottom: ${theme.space[4]};
  }
  margin-top: ${theme.space[5]};
  padding: 0 ${theme.space[6]};
`;

export const SkeletonHeaderTitle = styled.div`
  margin-bottom: ${theme.space[4]};
`;
export const LeftNavOuter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div {
    background: ${theme.color.grey[700]}!important;
  }
  div:first-child {
    margin-right: ${theme.space[3]};
  }
`;

export const FooterOuter = styled.div`
  :first-child {
    padding-top: 15px;
  }
  display: flex;
  div {
    background: ${theme.color.grey[700]}!important;
  }
  div:first-child {
    margin-right: ${theme.space[3]};
  }
  div:nth-child(3) {
    margin-left: auto;
  }
`;
export const SkeletonLogout = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-left: ${theme.space[3]};
  }
  div:last-child {
    margin-left: 20px;
  }
`;

export const SkeletonCard = styled.div`
  padding: ${theme.space[4]};
  border: 1px solid ${theme.color.grey[200]};
`;

export const CardInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    border-top: 1px solid ${theme.color.grey[200]};
    padding-top: ${theme.space[4]};
  }
  :first-child {
    padding-bottom: ${theme.space[4]};
  }
`;

export const SkeletonBodyCard = styled.div`
  border: 1px solid ${theme.color.grey[200]};
  padding: ${theme.space[4]};
  > div {
    margin: 0;
    div:last-child {
      margin: 0;
      div {
        margin-bottom: ${theme.space[1]};
      }
    }
    > div {
      margin: 0;
    }
  }
`;
