import styled from "styled-components";
import { theme } from "ds4-beta";
import { CollapsedType } from ".";

export const StyledLeftNavigationContainer = styled.div<{}>`
  display: flex;
  height: 100vh;
`;

export const StyledLeftNavigation = styled.div<CollapsedType>`
  display: flex;
  padding: ${theme.space[2]};
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${theme.color.grey[1000]};
  color: ${theme.color.grey[0]};
  // TODO: Remove this
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: ${({ collapsed }) => (collapsed ? "60px" : "240px")};
`;

export const StyledLeftNavMenuContainer = styled.div<{}>`
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
