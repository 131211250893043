export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#121213" />
    <path d="M20 10L32 30.7846H8L20 10Z" fill="#FFC738" />
  </svg>
);
