import AlertsLink from "./Alerts.data";
import AnalyticsLink from "./Analytics.data";
import CustomersLinks from "./CDP.data";
import CatalogConnectorLink from "./CatalogConnector.data";
import HomeLinks from "./Home.data";
import Inventory_Links from "./Inventory_data";
import MarketplaceLinks from "./MarketPlace.data";
import MemberLinks from "./Member.data";
import OMSLinks from "./OMS.data";
import OMS_V2Links from "./OMS_V2.data";
import OffersLinks from "./Offers.data";
import ProductLinks from "./PIM.data";
import SettingsLinks from "./Settings.data";
import SupplierLinks from "./Suppliers.data";
import SupplierLegacyLinks from "./SuppliersLegacy.data";
import XMLinks from "./XM.data";
import CommerceGPTLinks from "./fabAI.data";

export const defaultNavLinkData = [
  AlertsLink,
  CommerceGPTLinks,
  HomeLinks,
  AnalyticsLink,
  CatalogConnectorLink,
  ProductLinks,
  XMLinks,
  OffersLinks,
  OMSLinks,
  OMS_V2Links,
  Inventory_Links,
  CustomersLinks,
  MemberLinks,
  MarketplaceLinks,
  SettingsLinks,
  SupplierLinks,
  SupplierLegacyLinks,
];
