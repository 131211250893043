export default () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill="#121213" />
      <path
        d="M19.5 8L32 17.0818L27.2254 31.7764H11.7746L7 17.0818L19.5 8Z"
        fill="#189CFB"
      />
    </svg>
  );
};
