export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#121213" />
    <path
      d="M20 6L24.4548 15.5452L34 20L24.4548 24.4548L20 34L15.5452 24.4548L6 20L15.5452 15.5452L20 6Z"
      fill="#FF4D8D"
    />
  </svg>
);
