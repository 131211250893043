import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import {
  COPILOT_V1_OFFERS_COUPONS_READ,
  COPILOT_V1_OFFERS_PRICES_READ,
  COPILOT_V1_OFFERS_PROMOTIONS_READ,
  COPILOT_V1_OFFERS_SETTINGS_READ,
} from "../constants";
import ProductNavLinkType from "../types/ProductNavLink.type";

const OffersLinks = {
  label: "Offers",
  icon: "Pricing",
  featureFlag: "copilot-header-offers",
  children: [
    {
      label: "Pricing",
      url: COPILOT_ROUTES.offers.pricing,
      rbacAllowedPermissions: [COPILOT_V1_OFFERS_PRICES_READ],
    },
    {
      label: "Promotions",
      url: COPILOT_ROUTES.offers.promotions,
      rbacAllowedPermissions: [COPILOT_V1_OFFERS_PROMOTIONS_READ],
    },
    {
      label: "Coupons",
      url: COPILOT_ROUTES.offers.coupons.basePath,
      rbacAllowedPermissions: [COPILOT_V1_OFFERS_COUPONS_READ],
      featureFlag: "ui_redemption_export",
      flagState: false,
    },
    {
      label: "Coupons",
      url: COPILOT_ROUTES.offers.coupons.basePath,
      rbacAllowedPermissions: [COPILOT_V1_OFFERS_COUPONS_READ],
      featureFlag: "ui_redemption_export",
      flagState: true,
      children: [
        {
          label: "All coupons",
          url: COPILOT_ROUTES.offers.coupons.allCoupons,
        },
        {
          label: "Redemption",
          url: COPILOT_ROUTES.offers.coupons.redemption,
        },
      ],
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.offers.settings.basePath,
      rbacAllowedPermissions: [COPILOT_V1_OFFERS_SETTINGS_READ],
    },
  ],
} as ProductNavLinkType;

export default OffersLinks;
