import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const CatalogConnectorLink = {
  icon: "ElectricCord",
  label: "Catalog Connector",
  featureFlag: "catalog-connector-tenant",
  children: [
    {
      label: "List",
      children: [
        {
          label: "Items",
          url: COPILOT_ROUTES.catalogConnector.items,
        },
        {
          label: "Bundles",
          url: COPILOT_ROUTES.catalogConnector.bundles,
        },
      ],
      url: "/",
    },
  ],
} as ProductNavLinkType;

export default CatalogConnectorLink;
