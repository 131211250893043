import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";
import { LEFT_NAV_RESTRUCTURE_FF } from "../constants";

const OPERATIONAL_ALERTS_FF = "operational-alerts-nav";

const AlertsLink: ProductNavLinkType = {
  icon: "Megaphone",
  label: "Alerts",
  children: [],
  featureFlag: {
    flags: [LEFT_NAV_RESTRUCTURE_FF, OPERATIONAL_ALERTS_FF],
    flagExpression: (leftNavRestructure, operationalAlerts) =>
      leftNavRestructure && operationalAlerts,
  },
  url: COPILOT_ROUTES.home.alertList,
};

export default AlertsLink;
