import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const OPERATIONAL_ALERTS_FF = "operational-alerts-nav";

const HomeLinks = {
  icon: "Home",
  label: "Home",
  url: COPILOT_ROUTES.home.activity,
  children: [
    {
      label: "Dashboard",
      url: COPILOT_ROUTES.home.activity,
      featureFlag: OPERATIONAL_ALERTS_FF,
      flagState: true,
    },
    {
      label: "Alerts",
      url: COPILOT_ROUTES.home.alertList,
      featureFlag: OPERATIONAL_ALERTS_FF,
      flagState: true,
    },
  ],
} as ProductNavLinkType;
export default HomeLinks;
