import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";
import isLoggedInWithIdV2 from "../utils/isLoggedInWithIdV2";

const SettingsLinks: ProductNavLinkType = {
  icon: "Settings",
  label: "Settings",
  children: [
    //? Uncomment this Application Settings Route once the page is built
    // {
    //   label: "Applications Settings",
    //   url: "/settings/application-settings",
    //   children: [],
    // },
    {
      label: "Account Settings",
      children: [],
      url: COPILOT_ROUTES.settings.account.basePath,
    },
    {
      label: "Developer Tools",
      url: COPILOT_ROUTES.settings.devTools.basePath,
      enabled: isLoggedInWithIdV2(),
      children: [],
    },
  ],
};

export default SettingsLinks;
