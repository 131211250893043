export const SESSION_STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  ACCOUNT: "account",
  ACCOUNT_ID: "accountId",
  ORG_DETAIL: "orgDetail",
  TENANT_DETAIL: "tenantDetail",
  ACCOUNT_NAME: "accountName",
};

export const LOCAL_STORAGE_KEYS = {
  IS_ID_V2: "isLoggedInWithIdV2",
};

export const UNLEASH_APP_NAME = "mfa-left-navigation";

export const API_BASE_URL = "tenants.fabric.inc/v1";

export * from "./rbac_constants";

export const PRODUCT_NAME_MAPPING = {
  Products: "PIM",
  Orders: "OMS",
  Inventory: "OMS",
  Offers: "OFFERS",
  Customers: "CDP",
  Experiences: "XM",
  Marketplace: "DROPSHIP",
  Loyalty: "Loyalty",
  "Catalog Connector": "PIM_CONNECTOR",
  Suppliers: "SUPPLIERS",
};

export const SUPPORT_PAGE_LINK =
  "https://support.fabric.inc/hc/en-us/requests/new";
