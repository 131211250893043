import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const OMSLinks = {
  label: "Orders",
  icon: "Orders",
  featureFlag: "ui-v1",
  children: [
    {
      label: "Orders",
      url: COPILOT_ROUTES.oms.basePath,
    },
    {
      label: "Inventory",
      url: COPILOT_ROUTES.oms.inventory,
    },
    {
      label: "Warehouse",
      url: COPILOT_ROUTES.oms.warehouse,
    },
    {
      label: "History",
      url: COPILOT_ROUTES.oms.history,
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.oms.settings,
    },
  ],
} as ProductNavLinkType;

export default OMSLinks;
