import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const SupplierLegacyLinks = {
  label: "Suppliers",
  icon: "MarketPlace",
  featureFlag: "copilot-header-suppliers-legacy",
  children: [
    {
      label: "Overview",
      url: COPILOT_ROUTES.marketplace.overview,
    },
    {
      label: "Orders",
      children: [
        {
          label: "All Orders",
          url: COPILOT_ROUTES.marketplace.orders,
        },
        {
          label: "Invoices",
          url: COPILOT_ROUTES.marketplace.invoices,
        },
        {
          label: "Cancellations",
          url: COPILOT_ROUTES.marketplace.cancellations,
        },
        {
          label: "Returns",
          url: COPILOT_ROUTES.marketplace.returns,
        },
        {
          label: "Tracking Numbers",
          url: COPILOT_ROUTES.marketplace.trackingNumbers,
        }
      ]
    },
    {
      label: "Products",
      children: [
        {
          label: "All Products",
          url: COPILOT_ROUTES.marketplace.products,
        },
        {
          label: "Offers",
          url: COPILOT_ROUTES.marketplace.offers,
        },
        {
          label: "Proposals",
          url: COPILOT_ROUTES.marketplace.proposals,
        },
        {
          label: "Import Requests",
          url: COPILOT_ROUTES.marketplace.importRequests,
        }
      ]
    },
    {
      label: "Reports",
      children: [
        {
          label: "Performance Reporting",
          url: COPILOT_ROUTES.marketplace.performanceReporting,
        },
        {
          label: "Compliance Reporting",
          url: COPILOT_ROUTES.marketplace.complianceReporting,
        },
        {
          label: "Compliance Scorecard",
          url: COPILOT_ROUTES.marketplace.complianceScorecard,
        },
        {
          label: "Payments",
          url: COPILOT_ROUTES.marketplace.payments,
        }
      ]
    },
    {
      label: "Manage Suppliers",
      url: COPILOT_ROUTES.marketplace.basePath,
    },
    {
      // to do: show only when tenant is onboarding
      label: "Onboarding",
      url: COPILOT_ROUTES.marketplace.onboarding,
    },
  ],
} as ProductNavLinkType;

export default SupplierLegacyLinks;
