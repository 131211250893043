import { MenuItemProps } from "../App/LeftNavigation/MenuItem";

export const SupportLinks: MenuItemProps[] = [
  {
    label: "Knowledge Base",
    onClick: () => {
      window.open(
        "https://developer.fabric.inc/home",
        "_blank",
        "noopener,noreferrer"
      );
    },
    uniqueIdentifier: "help-&-support-knowledge-base-link",
    links: [],
  },
  {
    label: "Submit a Request",
    onClick: () => {
      window.open(
        "https://support.fabric.inc/hc/en-us/requests/new",
        "_blank",
        "noopener,noreferrer"
      );
    },
    uniqueIdentifier: "help-&-support-submit-a-request-link",
    links: [],
  },
];
