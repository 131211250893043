import { useState } from "react";
import { UserAccount } from "../App/LeftNavigation/AccountSwitcher/AccountSwitcher.type";
import { SESSION_STORAGE_KEYS } from "../constants";
import { api as API, ORGANIZATION_API_URLS } from "../utils/apitenants";
import isLoggedInWithIdV2 from "../utils/isLoggedInWithIdV2";
import { useUnleashContext, useFlag } from "@unleash/proxy-client-react";
import { rbac } from "@teamfabric/copilot-utilities";

export default function useOnAccountChange() {
  const [selectedAccount, setSelectedAccount] = useState<UserAccount>({
    accountId: window.sessionStorage?.accountId,
    name: "",
    tenantId: "",
    permissions: [],
  });
  const updateContext = useUnleashContext();
  const selfOnboarding = useFlag("copilot-self-onboarding");
  const isNewTerminologyEnabled = useFlag("copilot-header-terminology-update");
  const onChange = async (account: UserAccount) => {
    updateContext({
      userId: account.accountId,
    });

    const accountId = account.accountId;
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ACCOUNT_ID, accountId);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ACCOUNT_NAME, account?.name);

    const tenantId = isLoggedInWithIdV2()
      ? accountId
      : sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT);

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.ORG_DETAIL);

    if (selfOnboarding && tenantId) {
      try {
        const { data } = await API.post(
          ORGANIZATION_API_URLS.GET_ORG_BY_ID(tenantId)
        );
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.ORG_DETAIL,
          JSON.stringify(data)
        );
      } catch (e) {
        console.error(e);
      }
    }

    localStorage.setItem("lastSelectedAccountId", accountId);
    if (isLoggedInWithIdV2()) {
      // only in case if IdV2 login
      localStorage.setItem("userperm", JSON.stringify(account.permissions));
      rbac.refresh();
    }
    setSelectedAccount(account);
    if (selfOnboarding) {
      window.dispatchEvent(
        new CustomEvent("fetchSelfOnboardingData", {
          detail: { accountId, eventFromHeader: true, isNewRouteEnabled: isNewTerminologyEnabled, },
        })
      );
    } else {
      window.location.href = "/home/activity";
    }
  };
  return { selectedAccount, onChange };
}
