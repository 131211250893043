import { useMemo } from "react";
import { Modal, Carousel, useCarousel, ButtonProps } from "ds4-beta";
import { carouselOptions, modalData, getFooterButtons } from "./constant";

export const OnBoardingModal = ({ showModal, onModalClose }) => {
  const { activeIndex, onNext, onPrevious } = useCarousel(
    carouselOptions.length
  );
  const { title, description } = modalData[activeIndex];

  const footerButtons = useMemo(
    () =>
      getFooterButtons({
        activeIndex,
        onModalClose,
        onPrevious,
        onNext,
      }),
    [activeIndex, onNext, onPrevious, onModalClose]
  );

  return (
    <Modal
      size="small"
      isVisible={showModal}
      onClose={onModalClose}
      headerText={title}
      description={description}
      footerButtons={footerButtons as ButtonProps[]}
      dataTestid="onboarding-tour-modal"
    >
      <div style={{ marginTop: description ? "16px" : "0px" }}>
        <Carousel
          options={carouselOptions}
          {...(!description && { descriptionHeight: "80px" })}
        />
      </div>
    </Modal>
  );
};
