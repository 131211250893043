import CustomersLinks from "./CDP.data";
import HomeLinks from "./Home.data";
import MarketplaceLinks from "./MarketPlace.data";
import MemberLinks from "./Member.data";
import OMSLinks from "./OMS.data";
import OMS_V2Links from "./OMS_V2.data";
import Inventory_Links from "./Inventory_data";
import OffersLinks from "./Offers.data";
import ProductLinks from "./PIM.data";
import XMLinks from "./XM.data";
import SettingsLinks from "./Settings.data";
import CatalogConnectorLink from "./CatalogConnector.data";
import AnalyticsLink from "./Analytics.data";
import SupplierLinks from "./Suppliers.data";
import SupplierLegacyLinks from "./SuppliersLegacy.data";
import CommerceGPTLinks from "./fabAI.data";

export const defaultNavLinkData = [
    CommerceGPTLinks,
    HomeLinks,
    AnalyticsLink,
    CatalogConnectorLink,
    ProductLinks,
    XMLinks,
    OffersLinks,
    OMSLinks,
    OMS_V2Links,
    Inventory_Links,
    CustomersLinks,
    MemberLinks,
    MarketplaceLinks,
    SettingsLinks,
    SupplierLinks,
    SupplierLegacyLinks,
];
