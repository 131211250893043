import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const XMLinks = {
  label: "Experiences",
  icon: "Experiences",
  featureFlag: "copilot-header-xm",
  children: [
    {
      label: "Pages",
      url: COPILOT_ROUTES.xm.pages,
      children: [],
    },
    {
      label: "Global elements",
      url: COPILOT_ROUTES.xm.globalElements,
      children: [],
    },
    {
      label: "Menu",
      url: COPILOT_ROUTES.xm.browseMenu,
      featureFlag: "fe_new_menu_xm",
      flagState: true,
      children: [],
    },
    {
      label: "Menu",
      url: COPILOT_ROUTES.xm.menu,
      featureFlag: "fe_new_menu_xm",
      flagState: false,
      children: [],
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.xm.settings,
      featureFlag: "fe_use_hydra_xm",
      children: [],
    },
  ],
} satisfies ProductNavLinkType;

export default XMLinks;
