import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const MemberLinks = {
  label: "Loyalty",
  icon: "Loyalty",
  featureFlag: "copilot-header-member",
  children: [],
  url: COPILOT_ROUTES.member.basePath,
} satisfies ProductNavLinkType;

export default MemberLinks;
