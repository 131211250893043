export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#121213" />
    <path
      d="M20 6L23.2145 12.2394L29.8995 10.1005L27.7606 16.7855L34 20L27.7606 23.2145L29.8995 29.8995L23.2145 27.7606L20 34L16.7855 27.7606L10.1005 29.8995L12.2394 23.2145L6 20L12.2394 16.7855L10.1005 10.1005L16.7855 12.2394L20 6Z"
      fill="#FF8B37"
    />
  </svg>
);
