export default function DoubleArrow() {
  return (
    <svg width="16" height="16" viewBox="0 0 14 15" fill="none">
      <g>
        <path
          d="M7.30152 1.48999L1.15152 7.63999C1.10369 7.68488 1.06557 7.7391 1.03951 7.7993C1.01345 7.85949 1 7.92439 1 7.98999C1 8.05559 1.01345 8.12049 1.03951 8.18069C1.06557 8.24088 1.10369 8.2951 1.15152 8.33999L7.30152 14.49"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4529 1.48999L7.30289 7.63999C7.25505 7.68488 7.21693 7.7391 7.19087 7.7993C7.16481 7.85949 7.15137 7.92439 7.15137 7.98999C7.15137 8.05559 7.16481 8.12049 7.19087 8.18069C7.21693 8.24088 7.25505 8.2951 7.30289 8.33999L13.4529 14.49"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_508_41425">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.98999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
