import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const SettingsChildren = [
  {
    label: "Attributes",
    url: COPILOT_ROUTES.oms.v2.settings.attributes,
    featureFlag: "ds3-hide-oms-internal-nav",
    flagState: false,
    children: [],
  },
  {
    label: "Attributes",
    url: COPILOT_ROUTES.oms.v2.settings.attributesLocation,
    featureFlag: "ds3-hide-oms-internal-nav",
    children: [],
  },
  {
    label: "Shipping Methods",
    url: COPILOT_ROUTES.oms.v2.settings.shippingMethod,
    featureFlag: "ds3-hide-oms-internal-nav",
    flagState: false,
    children: [],
  },
  {
    label: "Shipment Methods",
    url: COPILOT_ROUTES.oms.v2.settings.shipping,
    featureFlag: "ds3-hide-oms-internal-nav",
    children: [],
  },
  {
    label: "Backorder & Preorder",
    url: COPILOT_ROUTES.oms.v2.settings.backAndPreOrder,
    featureFlag: "ds3-hide-oms-internal-nav",
    flagState: false,
    children: [],
  },
  {
    label: "Policies",
    url: COPILOT_ROUTES.oms.v2.settings.policies,
    featureFlag: "ds3-hide-oms-internal-nav",
    flagState: false,
    children: [],
  },
  {
    label: "Alerts",
    url: COPILOT_ROUTES.oms.v2.settings.alerts,
    featureFlag: "ORDERS_ALERTS",
    flagState: true,
    children: [],
  },
] satisfies ProductNavLinkType["children"];

const OMS_V2Links = {
  label: "Orders",
  icon: "Orders",
  featureFlag: "copilot-header-oms",
  children: [
    {
      label: "Manage orders",
      url: COPILOT_ROUTES.oms.v2.basePath,
      children: [],
    },
    {
      label: "Fulfillment",
      url: COPILOT_ROUTES.oms.v2.fulfillment,
      featureFlag: "ds3-hide-oms-internal-nav",
      flagState: false,
      children: [],
    },
    {
      label: "Activity Log",
      url: COPILOT_ROUTES.oms.v2.activityLog,
      featureFlag: "ORDERS_ACTIVITY_LOGS",
      flagState: true,
      children: [],
    },
    {
      label: "Inventory",
      url: COPILOT_ROUTES.oms.v2.inventory,
      featureFlag: "ds3-hide-oms-internal-nav",
      children: [],
    },
    {
      label: "Network",
      url: COPILOT_ROUTES.oms.v2.network,
      featureFlag: "ds3-hide-oms-internal-nav",
      children: [],
    },
    {
      label: "Locations",
      url: COPILOT_ROUTES.oms.v2.location,
      featureFlag: "ds3-hide-oms-internal-nav",
      children: [],
    },
    {
      label: "History",
      url: COPILOT_ROUTES.oms.v2.history,
      featureFlag: "ds3-hide-oms-internal-nav",
      children: [],
    },
    {
      label: "Alerts",
      url: COPILOT_ROUTES.oms.v2.alerts,
      featureFlag: "ORDERS_ALERTS",
      flagState: true,
      children: [],
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.oms.v2.settings.basePath,
      children: SettingsChildren,
    },
  ],
} satisfies ProductNavLinkType;

export default OMS_V2Links;
