import { MenuItemProps } from "../App/LeftNavigation/MenuItem/MenuItem.type";
import ProductNavLinkType from "../types/ProductNavLink.type";
import { useNavigate } from "react-router-dom";

/**
 * Translation Layer. Translates the product navlink data to the data that the Left Navigation Component from DS4 library accepts
 * @param entry Product Navigation Link
 * @param navigate Navigate Function from react-router-dom
 * @returns
 */
export const translateNavLink = (
  entry?: ProductNavLinkType,
  navigate?: ReturnType<typeof useNavigate>
): MenuItemProps => {
  if (!entry) return null;
  const links = entry.children
    ?.map((child) => translateNavLink(child, navigate))
    ?.filter((item) => item !== null);

  return {
    icon: entry.icon,
    active: entry.activeTab,
    expanded: entry.expanded,
    label: entry.label,
    links,
    onClick: entry.url
      ? () => {
          navigate(entry.url);
          // TODO: temp fix to load orders/orders
          if (entry.url === "/orders" || entry.url.startsWith("/offers")) {
            navigate(entry.url);
          }
        }
      : undefined,
    uniqueIdentifier: `${entry.label}-${entry.url}`,
  };
};
