import { LeftNavigationProps } from "../App/LeftNavigation";

const title = "fabric";

export const initialConfig: LeftNavigationProps = {
  accounts: [],
  headerProps: {
    title,
  },
  links: [],
};
