import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const Inventory_Links = {
  label: "Inventory",
  icon: "Inventory",
  featureFlag: {
    flags: ["ds3-hide-oms-internal-nav", "copilot-header-oms"],
    flagExpression: (
      hideDs3InternalNav: boolean,
      showCopilotOmsHeader: boolean
    ) => !hideDs3InternalNav && showCopilotOmsHeader,
  },
  children: [
    {
      label: "Manage inventory",
      url: COPILOT_ROUTES.inventory.manageInventory,
    },
    {
      label: "Networks",
      url: COPILOT_ROUTES.inventory.network,
    },
    {
      label: "Locations",
      url: COPILOT_ROUTES.inventory.location,
    },
    {
      label: "Activity Log",
      url: COPILOT_ROUTES.inventory.activityLog,
    },
  ],
} as ProductNavLinkType;

export default Inventory_Links;
