export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#121213" />
    <path
      d="M20 8L24.9656 20.1442L33 30.5167L20 28.7448L7 30.5167L15.0344 20.1442L20 8Z"
      fill="#943EFF"
    />
  </svg>
);
