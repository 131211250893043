import produce from "immer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { initialConfig } from "../data/initialConfig";
import useNavLinks from "../hooks/useNavLinks";
import { translateNavLink } from "../utils/translateNavLink";
import { LeftNavigation, LeftNavigationProps } from "./LeftNavigation";
import { useUnleashContext } from "@unleash/proxy-client-react";

type SessionStorageAccountsSchema = {
  id: string;
  tenantId: string;
  name: string;
  permissions: string[];
};

export default function App() {
  const [config, setConfig] = useState<LeftNavigationProps>(initialConfig);
  const navLinks = useNavLinks();
  const navigate = useNavigate();
  const updateContext = useUnleashContext();

  const updateListener = (e: CustomEvent) => {
    const hasSessionToken = Boolean(sessionStorage.getItem('accessToken'));
    updateContext({
      userId: hasSessionToken ? e.detail.accountId : null,
    });
  }

  useEffect(() => {
    window.addEventListener("onUserUpdate", updateListener);

    return () => {
      window.removeEventListener("onUserUpdate", updateListener);
    }
  }, []);

  useEffect(() => {
    setConfig((value) =>
      produce(value, (draft) => {
        draft.links = navLinks.map((navLink) =>
          translateNavLink(navLink, navigate)
        );
        const accounts: SessionStorageAccountsSchema[] = Array.from(
          JSON.parse(sessionStorage.getItem("accounts")) ?? []
        );
        draft.accounts = accounts.map((account, index) => ({
          accountId: account.id,
          tenantId: account.id,
          permissions: account.permissions,
          name: `${account.name}`,
        }));
      })
    );
  }, [navLinks]);
  return <LeftNavigation {...config} />;
}
