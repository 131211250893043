import { useState, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CarouselProvider } from "ds4-beta";
import { OnBoardingModal } from "./OnBoardingModal";
import { ONBOARDING_TOUR_FLAG } from "./constant";

export const OnBoardingTour = () => {
  const [showModal, setShowModal] = useState(true);

  const onBoardingTour = useFlag(ONBOARDING_TOUR_FLAG);
  const isOnBordingTourVisited = !!localStorage.getItem(
    "isOnBordingTourVisited"
  );

  const onModalClose = useCallback(() => {
    localStorage.setItem("isOnBordingTourVisited", "true");
    setShowModal(false);
  }, []);

  if (!onBoardingTour || isOnBordingTourVisited) {
    return null;
  }

  return (
    <CarouselProvider>
      <OnBoardingModal showModal={showModal} onModalClose={onModalClose} />
    </CarouselProvider>
  );
};
