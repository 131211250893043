import ProductNavLinkType from "../types/ProductNavLink.type";

const CommerceGPTLinks = {
    icon: "CommerceGPT",
    label: "Welcome",
    url: "/welcome",
    featureFlag: "fabai-home-page",
    children: [],
} as ProductNavLinkType;
export default CommerceGPTLinks;
