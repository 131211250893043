import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import { CUSTOMER_V1_INDIVIDUAL_READ } from "../constants";
import ProductNavLinkType from "../types/ProductNavLink.type";

const CustomersLinks = {
  label: "Customers",
  icon: "Customers",
  featureFlag: "copilot-header-customers",
  children: [
    {
      label: "Manage Customers",
      url: COPILOT_ROUTES.customer.customers,
      enabled: true,
      rbacAllowedPermissions: [CUSTOMER_V1_INDIVIDUAL_READ],
    },
    // {
    //   label: "Segments",
    //   url: "/customer/segments",
    //   featureFlag: "segmentation_customers",
    //   enabled: true,
    // },
    // {
    //   label: "Organizations",
    //   url: "/customer/organizations",
    //   featureFlag: "organization_customers",
    //   enabled: true,
    //   rbacAllowedPermissions: [CUSTOMER_V1_ORGANIZATION_READ],
    // },
    // {
    //   label: "Contracts",
    //   url: "/customer/contracts",
    //   enabled: false,
    //   rbacAllowedPermissions: [CUSTOMER_V1_CONTRACTS_READ],
    // },
  ],
} as ProductNavLinkType;

export default CustomersLinks;
