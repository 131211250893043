import React from "react";
import { Skeleton, SkeletonIcon } from "ds4-beta";
import FabricLogoNew from "../../assets/fabricLogo";
import {
  StyledLoadingContainer,
  StyledLeftNavLoadingContainer,
  SkeletonLogo,
  SkeletonLeftNavItems,
  SkeletonFooter,
  LeftNavOuter,
  FooterOuter,
} from "./styles";

const DS4PageLoader = () => (
  <StyledLoadingContainer>
    <StyledLeftNavLoadingContainer>
      <SkeletonLogo>
        <FabricLogoNew className="logo" />
      </SkeletonLogo>
      <SkeletonLeftNavItems>
        <LeftNavOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"55px"} height="20px" />
        </LeftNavOuter>
        <LeftNavOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"74px"} height="20px" />
        </LeftNavOuter>
        <LeftNavOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"89px"} height="20px" />
        </LeftNavOuter>
        <LeftNavOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"66px"} height="20px" />
        </LeftNavOuter>
        <LeftNavOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"77px"} height="20px" />
        </LeftNavOuter>
      </SkeletonLeftNavItems>
      <SkeletonFooter>
        <FooterOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"77px"} height="20px" />
        </FooterOuter>
        <FooterOuter>
          <SkeletonIcon size={20} />
          <Skeleton width={"77px"} height="20px" />
          <SkeletonIcon size={20} />
        </FooterOuter>
      </SkeletonFooter>
    </StyledLeftNavLoadingContainer>
  </StyledLoadingContainer>
);

export default DS4PageLoader;
