import { LogicalFeatureFlagType } from "../types/ProductNavLink.type";
import isLoggedInWithIdV2 from "../utils/isLoggedInWithIdV2";
import useUnleashEnableContext from "./useUnleashEnableContext";
import { rbac } from "@teamfabric/copilot-utilities";
import isEmpty from "lodash/isEmpty";

const isFeatureFlagEnabled = (
  featureFlag: string | LogicalFeatureFlagType,
  isEnabled: (toggleName: string) => boolean
) => {
  if (typeof featureFlag === "string") return isEnabled(featureFlag);
  const flagValues = featureFlag.flags.map((flag) => isEnabled(flag));
  return featureFlag.flagExpression(...flagValues);
};

const hasRbacPermission = (
  isEnabled: (toggleName: string) => boolean,
  allowedPermissions?: string[]
) => {
  const rbacFlag = isEnabled("rbac_v2");
  const isIdv2Login = isLoggedInWithIdV2();
  if (!rbacFlag || !isIdv2Login) return true;
  const { hasAccess } = rbac.checkPermission({
    allowedPermissions,
  });
  return hasAccess;
};

const useIsEnabled = () => {
  const isEnabled = useUnleashEnableContext();

  return (
    featureFlag?: string | LogicalFeatureFlagType,
    allowedPermissions?: string[],
    enabled?: boolean,
    flagState: boolean = true
  ) => {
    if (enabled !== undefined && !enabled) return false;
    if (featureFlag) {
      const featureEnabled = isFeatureFlagEnabled(featureFlag, isEnabled);
      if (featureEnabled !== flagState) return false;
    }
    return (
      isEmpty(allowedPermissions) ||
      hasRbacPermission(isEnabled, allowedPermissions)
    );
  };
};

export default useIsEnabled;
