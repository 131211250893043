import axios from "axios";
import { API_BASE_URL, SESSION_STORAGE_KEYS } from "../constants";
import isLoggedInWithIdV2 from "./isLoggedInWithIdV2";

const stageName = process.env.STAGE;
const isIdv2User = isLoggedInWithIdV2();

const getBaseUrl = () => {
  if (stageName === "live") return API_BASE_URL;
  return `${stageName}.${API_BASE_URL}`;
};

export const api = axios.create({
  responseType: "json",
  baseURL: getBaseUrl(),
  headers: {
    "x-fabric-tenant-id": isIdv2User
      ? sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT_ID)
      : sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT),
  },
});

api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${sessionStorage.getItem(
    SESSION_STORAGE_KEYS.ACCESS_TOKEN
  )}`;
  return request;
});

export const ORGANIZATION_API_URLS = Object.freeze({
  GET_ORG_BY_ID: (tenantId) => `tenants/${tenantId}/actions/get-organization`,
});
