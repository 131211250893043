import styled from "styled-components";
import { theme } from "ds4-beta";

export const StyledLeftMenuLabel = styled.div<{
  depth: number;
  active: boolean;
  expanded: boolean;
}>`
  ${({ depth, active, expanded }) =>
    depth === 0
      ? theme.typography.navigationAppTitle[expanded ? "active" : "inactive"]
      : theme.typography.body1[expanded || active ? "medium" : "regular"]}
  text-align: left;
  flex: 1 1 auto;
  margin: auto;
  padding-left: ${({ depth }) =>
    `${depth < 3 ? [0, 8, 30][depth] : 30 + (depth - 2) * 10}px`};
`;

export const StyledNavIconContainer = styled.div<{ navIcon?: boolean }>`
  margin: auto;
  ${({ navIcon }) => !navIcon && `padding-right: 10px;`}
`;

export const StyledMenuChildrenContainer = styled.div<{ depth?: number }>`
  ${({ depth }) => depth === 0 && `margin-bottom: ${theme.space[4]};`};
`;

export const StyledMenuItemContainer = styled.div<{
  active: boolean;
  depth: number;
}>`
  color: ${({ active, depth }) => theme.color.grey[!depth || active ? 0 : 200]};
  display: flex;
  cursor: pointer;
  border-radius: ${theme.space[1]};
  min-height: ${({ depth }) => (depth === 0 ? 36 : 30)}px;
  padding: 0 ${theme.space[3]};
  background-color: ${({ active }) =>
    active ? theme.color.navigation.active : ""};
  &:hover {
    background-color: ${theme.color.navigation.hover};
  }
`;
