import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const SupplierLinks = {
  label: "Suppliers",
  icon: "MarketPlace",
  featureFlag: "copilot-header-suppliers",
  children: [
    {
      label: "Manage Suppliers",
      url: COPILOT_ROUTES.marketplace.basePath,
    },
    {
      label: "Proposals",
      url: COPILOT_ROUTES.marketplace.proposals,
    },
    {
      label: "Settings",
      url: COPILOT_ROUTES.marketplace.settings,
    }
  ],
} as ProductNavLinkType;

export default SupplierLinks;
