import CopilotIntro from "../../assets/onboarding/fresh-copilot.jpg";
import Navigation from "../../assets/onboarding/new-navigation.gif";
import AccountSwitcher from "../../assets/onboarding/switch-accounts.gif";
import SearchFilter from "../../assets/onboarding/search-filter-sort.gif";
import FasterWorkflow from "../../assets/onboarding/faster-workflow.gif";
export const ONBOARDING_TOUR_FLAG = "onboardingtour";

export const carouselOptions = [
  {
    url: CopilotIntro,
    description:
      "Click through to explore our new navigation, layout, upgraded search and filtering, and many other UI/UX improvements. You can skip at any point.",
  },
  {
    url: Navigation,
    description:
      "Our centralized left-side navigation along with a new breadcrumb on every page will allow you to move easier and faster within Copilot. You can also expand & collapse your side-bar at any time.",
  },
  {
    url: AccountSwitcher,
    description:
      "Our new navigation also includes an account switcher functionality. Now you can easily go back and forth between your active accounts.",
  },
  {
    url: SearchFilter,
    description:
      "We’ve added a smart filtering system that allows you to select specific criteria on top of your search to narrow down your results. If your search returns with no results, we’ll smart-suggest where you might want to go.",
  },
  {
    url: FasterWorkflow,
    description:
      "Your teams needs an interface that speeds up their workflows – whether it is deploying a new promotion, adding inventory items, or editing an existing order. Our new UI and upgraded terminology will minimize errors, eliminate dead-ends, and allow easier completion of their daily tasks.",
  },
];

export const modalData = [
  {
    title: "Introducing a fresh new look for Copilot!",
    description:
      "We are excited to launch fabric’s new design system powering the next evolution of our Copilot platform experience.",
  },
  {
    title: "New Navigation",
    description: "",
  },
  {
    title: "Switch accounts instantly",
    description: "",
  },
  {
    title: "Search, filter, and sort better",
    description: "",
  },
  {
    title: "Faster workflows with consistent patterns",
    description: "",
  },
];

export const getFooterButtons = ({
  activeIndex,
  onModalClose,
  onPrevious,
  onNext,
}) => {
  const lastIndex = activeIndex === carouselOptions.length - 1;
  const buttons = [
    {
      text: "Skip Tour",
      variant: "tertiary",
      onClick: onModalClose,
      margin: "0 auto 0 0",
      dataTestid: "skip-tour-btn",
    },
    {
      text: "Back",
      variant: "secondary",
      onClick: onPrevious,
      dataTestid: "back-btn",
    },
    {
      text: lastIndex ? "Done" : "Next",
      variant: "primary",
      onClick: lastIndex ? onModalClose : onNext,
      dataTestid: "next-btn",
    },
  ];

  return activeIndex > 0 ? buttons : [buttons[0], buttons[2]];
};
