export default () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#121213" />
    <path
      d="M20.2583 7L31.5167 13.5V26.5L20.2583 33L9 26.5V13.5L20.2583 7Z"
      fill="#00E484"
    />
  </svg>
);
