import React, { useEffect, useState } from "react";
import { InternalMenuItemProps, MenuItemProps } from "./MenuItem.type";
import {
  StyledNavIconContainer,
  StyledLeftMenuLabel,
  StyledMenuChildrenContainer,
  StyledMenuItemContainer,
} from "./MenuItem.style";
import { Icon, Tooltip, theme } from "ds4-beta";
import isFunction from "lodash/isFunction";
import { WrapWithTooltip } from "../../../utils/wrapWithTooltip";

const IconFillType = {
  Fill: "fill",
  Outline: "outline",
};

export default function MenuItem({
  icon,
  expanded,
  depth = 0,
  label,
  active,
  links,
  onClick,
  collapsed,
  uniqueIdentifier,
  setCollapsed,
}: MenuItemProps & InternalMenuItemProps) {
  const [locallyExpanded, setLocallyExpanded] = useState(expanded);
  const onMenuItemClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (depth === 0 && collapsed) setCollapsed(false);
    if (depth === 0 || links.length === 0) isFunction(onClick) && onClick(e);
    else setLocallyExpanded((locallyExpanded) => !locallyExpanded);
  };

  const isExpanded = () => {
    return depth === 0 ? expanded : locallyExpanded;
  };

  useEffect(() => {
    if (depth > 0 && expanded) {
      setLocallyExpanded(expanded);
    }
  }, [expanded]);

  return (
    <>
      <StyledMenuItemContainer
        active={(collapsed && expanded) || active}
        depth={depth}
        onClick={onMenuItemClick}
        key={`Menu-Item-Container-${uniqueIdentifier}`}
        data-testid={`menu-item-${label}`}
      >
        {icon && (
          <WrapWithTooltip showTooltip={collapsed} label={label}>
            <StyledNavIconContainer
              className="left-nav-menu"
              data-testid={`left-nav-menu-icon-${label}`}
            >
              <Icon
                size={20}
                color={
                  icon === "Orders" && (expanded || active)
                    ? theme.color.grey[1000]
                    : theme.color.grey[0]
                }
                iconName={icon}
                variant={
                  ((expanded || active) && icon !== 'CommerceGPT'
                    ? IconFillType.Fill
                    : IconFillType.Outline) as any
                }
              />
            </StyledNavIconContainer>
          </WrapWithTooltip>
        )}
        {!collapsed && (
          <>
            <StyledLeftMenuLabel
              depth={depth}
              active={!!active}
              expanded={!!expanded}
              data-testid={`left-nav-menu-label-${label}`}
            >
              {label}
            </StyledLeftMenuLabel>
            {depth > 0 && links.length > 0 && (
              <StyledNavIconContainer
                navIcon
                aria-label={`Show more ${label}`}
                className="toggle-left-nav-menu"
                data-testid={`toggle-left-nav-menu-${label}`}
              >
                <Icon
                  size={16}
                  color={theme.color.grey[0]}
                  iconName={isExpanded() ? "ArrowDown" : "ArrowRight"}
                />
              </StyledNavIconContainer>
            )}
          </>
        )}
      </StyledMenuItemContainer>
      {!collapsed && isExpanded() && links.length > 0 && (
        <StyledMenuChildrenContainer
          depth={depth}
          key={`Menu-Item-Children-Container-${uniqueIdentifier}`}
        >
          {links.map((link) => (
            <MenuItem
              key={`Menu-Item-${link.uniqueIdentifier}-${depth}`}
              {...link}
              depth={depth + 1}
              collapsed={collapsed}
            />
          ))}
        </StyledMenuChildrenContainer>
      )}
    </>
  );
}
