import MenuItem from "../MenuItem/MenuItem";
import { StyledLeftNavFooter, StyledDivider } from "./LeftNavFooter.style";
import { LeftNavFooterProps } from "./LeftNavFooter.type";
import { FOOTER_LINKS, LEFT_NAV_RESTRUCTURE_FF } from "../../../constants";
import useIsEnabled from "../../../hooks/useIsEnabled";
import { HelpAndSupportButton, SupportButton } from "./HelpAndSupportButton";

export default function LeftNavFooter({
  links,
  collapsed,
  setCollapsed,
}: LeftNavFooterProps) {
  const isEnabled = useIsEnabled();
  return (
    <StyledLeftNavFooter>
      <StyledDivider />
      {links.map((link) =>
        FOOTER_LINKS.includes(link.label) ? (
          <MenuItem
            {...link}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            key={`Footer-Menu-Item-${link.uniqueIdentifier}`}
            // TODO: Remove when settings page is ready and there is a valid route available
            expanded={link.expanded}
          />
        ) : null
      )}
      {isEnabled(LEFT_NAV_RESTRUCTURE_FF) ? (
        <HelpAndSupportButton
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      ) : (
        <SupportButton collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
    </StyledLeftNavFooter>
  );
}
