import React, { useEffect, useState } from "react";
import LeftNavHeader from "./LeftNavHeader/LeftNavHeader";
import LeftNavFooter from "./LeftNavFooter/LeftNavFooter";
import {
  StyledLeftNavigation,
  StyledLeftNavigationContainer,
  StyledLeftNavMenuContainer,
} from "./LeftNavigation.style";
import AccountSwitcher from "./AccountSwitcher";
import { LeftNavigationProps } from "./LeftNavigation.type";
import MenuItem from "./MenuItem/MenuItem";
import { useLocation } from "react-router-dom";
import { OnBoardingTour } from "../../components/onboardingtour/index";

const LeftNavigation: React.FC<LeftNavigationProps> = ({
  links,
  accounts,
  headerProps,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [showAccountSwitcher, setShowAccountSwitcher] = useState(false);
  const toggleAccountSwitcher = () =>
    setShowAccountSwitcher(!showAccountSwitcher);

  const setCollapseWithTransition = (value: boolean) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => {
        setCollapsed(value);
      });
    } else {
      setCollapsed(value);
    }
  };

  return (
    <StyledLeftNavigationContainer>
      {showAccountSwitcher && <AccountSwitcher accounts={accounts} />}
      <StyledLeftNavigation collapsed={collapsed}>
        <LeftNavHeader
          multiAccount={accounts.length > 1}
          accountSwitcherState={showAccountSwitcher}
          {...{
            ...headerProps,
            collapsed,
            toggleAccountSwitcher,
          }}
        />
        <StyledLeftNavMenuContainer className="left-nav-contents">
          {links.map((link) =>
            link.label !== "Settings" ? (
              <MenuItem
                {...link}
                collapsed={collapsed}
                setCollapsed={setCollapseWithTransition}
                key={`Menu-Item-${link.uniqueIdentifier}`}
              />
            ) : null
          )}
        </StyledLeftNavMenuContainer>
        <LeftNavFooter
          links={links}
          collapsed={collapsed}
          setCollapsed={setCollapseWithTransition}
        />
      </StyledLeftNavigation>
      <OnBoardingTour />
    </StyledLeftNavigationContainer>
  );
};

export default LeftNavigation;
