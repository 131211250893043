export const CUSTOMER_V1_INDIVIDUAL_READ = "customer:v1:individual:read";
export const CUSTOMER_V1_ORGANIZATION_READ = "customer:v1:organization:read";
export const CUSTOMER_V1_CONTRACTS_READ = "customer:v1:contract:read";

export const COPILOT_V1_OFFERS_PRICES_READ = "copilot:v1:offers.prices:read";
export const COPILOT_V1_OFFERS_PROMOTIONS_READ =
  "copilot:v1:offers.promotions:read";
export const COPILOT_V1_OFFERS_COUPONS_READ = "copilot:v1:offers.coupons:read";
export const COPILOT_V1_OFFERS_SETTINGS_READ =
  "copilot:v1:offers.settings:read";
