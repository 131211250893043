import React from "react";
import { Icon, Tooltip, theme } from "ds4-beta";
import DoubleArrow from "../../DoubleArrow";
import MenuItem from "../MenuItem/MenuItem";
import {
  StyledLeftNavFooter,
  StyledFooterButtons,
  StyledSupportButton,
  DoubleArrowInteractive,
  StyledDivider,
} from "./LeftNavFooter.style";
import { LeftNavFooterProps } from "./LeftNavFooter.type";
import { SUPPORT_PAGE_LINK } from "../../../constants";
import { WrapWithTooltip } from "../../../utils/wrapWithTooltip";

enum IconFillType {
  Fill = "fill",
  Outline = "outline",
}

export default function LeftNavFooter({
  links,
  collapsed,
  setCollapsed,
}: LeftNavFooterProps) {
  return (
    <StyledLeftNavFooter>
      <StyledDivider />
      {links.map((link) =>
        link.label === "Settings" ? (
          <MenuItem
            {...link}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            key={`Footer-Menu-Item-${link.uniqueIdentifier}`}
            // TODO: Remove when settings page is ready and there is a valid route available
            expanded={link.expanded}
          />
        ) : null
      )}
      <StyledFooterButtons data-collapsed={collapsed}>
        <WrapWithTooltip showTooltip={collapsed} label={"Support"}>
          <StyledSupportButton
            data-testid="left-navigation-support-button"
            href={SUPPORT_PAGE_LINK}
            target="_blank"
            collapsed={collapsed}
          >
            <Icon
              size={20}
              color={theme.color.grey[0]}
              iconName={"Support"}
              variant={IconFillType.Outline}
              data-testid="left-navigation-support-icon"
            />
            {!collapsed && <div className="support-label">Support</div>}
          </StyledSupportButton>
        </WrapWithTooltip>
        <DoubleArrowInteractive
          onClick={() => setCollapsed((collapsed) => !collapsed)}
          data-collapsed={collapsed}
          data-testid="left-navigation-collapse-button"
        >
          <DoubleArrow />
        </DoubleArrowInteractive>
      </StyledFooterButtons>
    </StyledLeftNavFooter>
  );
}
