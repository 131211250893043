import React, { useState } from "react";
import { Box, Icon, theme } from "ds4-beta";
import DoubleArrow from "../../DoubleArrow";
import MenuItem from "../MenuItem/MenuItem";
import {
  StyledFooterButton,
  StyledSupportButton,
  DoubleArrowInteractive,
} from "./LeftNavFooter.style";
import { WrapWithTooltip } from "../../../utils/wrapWithTooltip";
import { SupportLinks } from "../../../data/Support.data";
import { SUPPORT_PAGE_LINK } from "../../../constants";

enum IconFillType {
  Fill = "fill",
  Outline = "outline",
}

const HelpAndSupportButton = ({
  collapsed,
  setCollapsed,
}: Readonly<{
  collapsed: boolean;
  setCollapsed: (
    collapsed: boolean | ((prevState: boolean) => boolean)
  ) => void;
}>) => {
  const [expanded, setExpanded] = useState(false);

  const expandCollapsedNav = () => {
    setCollapsed(false);
    setExpanded(true);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onSupportButtonClick = () => {
    if (collapsed) {
      expandCollapsedNav();
      return;
    }
    toggleExpanded();
  };

  const onCollapseButtonClick = () => {
    setCollapsed((collapsed) => !collapsed);
    setExpanded(false);
  };

  return (
    <StyledFooterButton data-expanded={expanded} data-collapsed={collapsed}>
      <WrapWithTooltip showTooltip={collapsed} label={"Help & Support"}>
        <Box
          flex={{
            flexDirection: "column",
          }}
        >
          <StyledSupportButton
            data-testid="left-navigation-support-button"
            collapsed={collapsed}
            onClick={onSupportButtonClick}
          >
            <Icon
              size={20}
              color={theme.color.grey[0]}
              iconName={"Support"}
              variant={expanded ? IconFillType.Fill : IconFillType.Outline}
              data-testid="left-navigation-support-icon"
            />
            {!collapsed && <div className="support-label">Help & Support</div>}
          </StyledSupportButton>
          {!collapsed &&
            expanded &&
            SupportLinks.map((link) => (
              <MenuItem
                key={`Menu-Item-${link.uniqueIdentifier}`}
                {...link}
                depth={1}
                collapsed={collapsed}
              />
            ))}
        </Box>
      </WrapWithTooltip>
      <DoubleArrowInteractive
        onClick={onCollapseButtonClick}
        data-collapsed={collapsed}
        data-testid="left-navigation-collapse-button"
      >
        <DoubleArrow />
      </DoubleArrowInteractive>
    </StyledFooterButton>
  );
};

const SupportButton = ({
  collapsed,
  setCollapsed,
}: Readonly<{
  collapsed: boolean;
  setCollapsed: (
    collapsed: boolean | ((prevState: boolean) => boolean)
  ) => void;
}>) => {
  return (
    <StyledFooterButton data-collapsed={collapsed}>
      <WrapWithTooltip showTooltip={collapsed} label={"Support"}>
        <StyledSupportButton
          data-testid="left-navigation-support-button"
          href={SUPPORT_PAGE_LINK}
          target="_blank"
          collapsed={collapsed}
        >
          <Icon
            size={20}
            color={theme.color.grey[0]}
            iconName={"Support"}
            variant={IconFillType.Outline}
            data-testid="left-navigation-support-icon"
          />
          {!collapsed && <div className="support-label">Support</div>}
        </StyledSupportButton>
      </WrapWithTooltip>
      <DoubleArrowInteractive
        onClick={() => setCollapsed((collapsed) => !collapsed)}
        data-collapsed={collapsed}
        data-testid="left-navigation-collapse-button"
      >
        <DoubleArrow />
      </DoubleArrowInteractive>
    </StyledFooterButton>
  );
};

export { SupportButton, HelpAndSupportButton };
