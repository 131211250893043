import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ProductNavLinkType from "../types/ProductNavLink.type";

const MarketplaceLinks = {
  label: "Marketplace",
  icon: "MarketPlace",
  featureFlag: "copilot-header-marketplace",
  children: [
    {
      label: "Overview",
      url: COPILOT_ROUTES.marketplace.overview,
    },
    {
      label: "Orders",
      children: [
        {
          label: "All Orders",
          url: COPILOT_ROUTES.marketplace.orders,
        },
        {
          label: "Invoices",
          url: COPILOT_ROUTES.marketplace.invoices,
        },
        {
          label: "Cancellations",
          url: COPILOT_ROUTES.marketplace.cancellations,
        },
        {
          label: "Returns",
          url: COPILOT_ROUTES.marketplace.returns,
        },
        {
          label: "Tracking Numbers",
          url: COPILOT_ROUTES.marketplace.trackingNumbers,
        }
      ]
    },
    {
      label: "Products",
      children: [
        {
          label: "All Products",
          url: COPILOT_ROUTES.marketplace.products,
        },
        {
          label: "Offers",
          url: COPILOT_ROUTES.marketplace.offers,
        },
        {
          label: "Proposals",
          url: COPILOT_ROUTES.marketplace.productProposals,
        },
        {
          label: "Import Requests",
          url: COPILOT_ROUTES.marketplace.importRequests,
        }
      ]
    },
    {
      label: "Reports",
      children: [
        {
          label: "Compliance Score Card",
          url: COPILOT_ROUTES.marketplace.complianceScorecard,
        },
        {
          label: "Job Reports",
          url: COPILOT_ROUTES.marketplace.jobs,
        },
        {
          label: "Exception Reports",
          url: COPILOT_ROUTES.marketplace.exceptions,
        }
      ]
    },
    {
      label: "Merchants",
      url: COPILOT_ROUTES.marketplace.merchants,
    },
    {
      // to do: show only when tenant is onboarding
      label: "Onboarding",
      url: COPILOT_ROUTES.marketplace.onboarding,
    },
  ],
} as ProductNavLinkType;

export default MarketplaceLinks;
